import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Just like components in design and development that can be reusable for products needs, UX copy also has several patterns that can be used to establish an easy, consistent, and clear content. These patterns include parts that almost every experience uses.`}</p>
    <div style={{
      "heigh": "1px",
      "marginBottom": "4rem"
    }} />
    <h2>{`Button / Call to action`}</h2>
    <p>{`Buttons are interactive elements which give users directions to the next step or encourage them to take an action that we want. Buttons include important copy text to complement the experience. Crafting copy for button needs to be specific, recognizable, and only one or two words long (if possible).`}</p>
    <p>{`Writing guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Use action verbs: When users read an action verb, they know what the button will do. Especially, if it’s followed by specific connotations.`}</li>
      <li parentName="ul">{`Use task-specific language: A generic copy on buttons can cause uncertainty in users. They’re unsure what the button will do if the copy on the button doesn’t specify.`}</li>
      <li parentName="ul">{`Use active imperative form: In order to make the copy concise, the active imperative form turns verb phrases into commands. We can drop the subject and articles which are not needed, and only include a verb with an adverb or direct object.`}</li>
    </ul>
    <div style={{
      "heigh": "1px",
      "marginBottom": "4rem"
    }} />
    <h2>{`Empty States`}</h2>
    <p>{`Empty states are what the users see when there is no data to display on a page. This can occur with a new product or feature when users haven’t done any task in it, and also occur when users perform a search that returns no result. Empty states should not be left empty as they can be used to engage our users and to tell them what the purpose of the page is.`}</p>
    <p>{`Writing guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Write about the purpose of the page: State what is supposed to be on the page or what users can be done here, what this feature does, how it can help them, or add instruction to start using the feature.`}</li>
      <li parentName="ul">{`Use clear structure: Using title, description (the purpose or instruction), and button can be helpful to convey the message.`}</li>
    </ul>
    <div style={{
      "heigh": "1px",
      "marginBottom": "4rem"
    }} />
    <h2>{`Error Messages`}</h2>
    <p>{`Error messages are used to inform the users that something went wrong and guide them to do the right step. To maintain trust, avoid putting blame on users. Encountering an error message is already a pain as it temporarily stops the process they are trying to complete. Therefore, we need to explain it simply and clearly, provide solutions, and make a pleasant experience out of it.`}</p>
    <p>{`Writing guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Precisely describe what went wrong or the proble`}</li>
      <li parentName="ul">{`Provide suggestions to solve the problem they encounter and to continue their action task. If the problem can’t be solved right away, inform them who or where they can go to help them.`}</li>
      <li parentName="ul">{`Be clear and conversational, just like how we explain a technical error to our non-technical friend`}</li>
    </ul>
    <div style={{
      "heigh": "1px",
      "marginBottom": "4rem"
    }} />
    <h2>{`Success Messages`}</h2>
    <p>{`Success Messages are one of the most important responses that the users expect to receive at the end of some journeys in the experience. These are also used to inform them that the action they have completed was successful.`}</p>
    <p>{`Writing guidelines:`}</p>
    <p>{`Provide messages that confirm to the user that the action completed successfully and that everything is okay`}</p>
    <ul>
      <li parentName="ul">{`Guide them to the next optional or mandatory ste`}</li>
      <li parentName="ul">{`Add messages that leave users with a good positive feelin`}</li>
      <li parentName="ul">{`For a frequent and simple task action, we can use a short and practical success message`}</li>
      <li parentName="ul">{`For more important task action, the message can use ‘title, description, button’ structure to clearly explain the message`}</li>
    </ul>
    <div style={{
      "heigh": "1px",
      "marginBottom": "4rem"
    }} />
    <h2>{`Placeholders`}</h2>
    <p>{`Placeholders are the text we write inside a field, usually in a light shade, and disappear or move when the user starts to type. The placeholder text needs to help the users to enter accurate information. It can be done by prefilling the placeholder with an example of the correct information, using labels outside the placeholders, or hints to indicate what information to enter.`}</p>
    <p>{`Writing guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Separate labels and placeholders. Make sure every placeholder, especially in a form, the label or the title does not have the same copy with the placeholders. In order to minimize redundancy.`}</li>
      <li parentName="ul">{`Hints in placeholders are only for short and ‘easy-to-read’ information. If the hints are important and the users might need to refer to those while typing, we can put them as tooltips instead.`}</li>
      <li parentName="ul">{`Use verb-first instruction about entering information to encourage more usage`}</li>
      <li parentName="ul">{`Placeholder text can include questions, categories, examples, or guiding sentence`}</li>
    </ul>
    <div style={{
      "heigh": "1px",
      "marginBottom": "4rem"
    }} />
    <h2>{`Labels`}</h2>
    <p>{`Labels are used to name or describe sections, categories, status, progress, quantity, or unit in a compact and clear format to minimize the effort required to understand the experience. They usually compliment passive screen elements like icons or sections. When deciding copy for labels, just like the other patterns, it needs to be aligned to the voice of the product.`}</p>
    <p>{`Writing guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Can be single noun or noun pairs, using noun phrases and adjective`}</li>
      <li parentName="ul">{`Use specific terms but avoid unfamiliar jargons to minimize the effort to understand the experience`}</li>
    </ul>
    <div style={{
      "heigh": "1px",
      "marginBottom": "4rem"
    }} />
    <h2>{`Notifications`}</h2>
    <p>{`Notifications are text to inform users to engage with experiences in our products. They are reminders or information that ‘interrupt’ users in order for them to pay attention to our experiences. These should include valuable information or sometimes urgent, and be packaged with the touch of our respective voice of tone.`}</p>
    <p>{`Writing guidelines:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Use a structure with two pieces of text (title and description pattern`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Title should begin with a verb that relates to the action they should take. Important information should be stated clearly in the title.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use description to explain or convey benefits of the action or any other ‘nice to have’ information`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      